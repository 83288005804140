import React, { Component } from 'react';

import './CajaHerramientas.css';

//own components
import ListCajaHerramientas from './ListCajaHerramientas';

import { config_api } from '../../config';
import Loader from '../Loader';

class CajaHerramientas extends Component {

  constructor() {
    super();
    this.state = {
      ListaDatos: [],
      lastIndex: 0,
      orderBy: 'views'
    };
  }

  changeOrder = (order, dir) => {
    this.setState({
      orderBy: order,
      orderDir: dir
    })
  }

  searchApts = (query) => {
    this.setState({
      queryText: query
    })
  }

  toggleOrder = () => {
    this.setState({ orderDir: !this.state.orderDir });
  }

  componentDidMount() {
    fetch(config_api + 'herramientas_videos.php')
      .then(response => response.json())
      .then(result => {
        console.log(result)
        let items = result.map(item => {
          item.id_herramientas_videos = this.state.lastIndex;
          this.setState({ lastIndex: this.state.lastIndex + 1 })
          return item;
        })
        this.setState({
          ListaDatos: items
        })
      }
      );
  }

  render() {

    let order;
    let ListaFiltrada = this.state.ListaDatos;
    if (this.props.orderDir) {
      order = 1;
    } else {
      order = -1;
    }

    ListaFiltrada = ListaFiltrada.sort((a, b) => {
      if (parseInt(a[this.state.orderBy]) > parseInt(b[this.state.orderBy])) {
        return -1 * order;
      } else {
        return 1 * order;
      }
    }).filter(eachItem => {
      return (
        accent_fold(eachItem['nombre_herramientas_videos'].toLowerCase())
          .includes(
            accent_fold(this.props.queryText)
              .toLowerCase())

      )
    });

    console.log(ListaFiltrada)

    return (
      <main className="page bg-white" id="petratings">
        <div className="container">
          <div className="row">
            <div className="col-md-12 bg-white">
              <div className="container">

                {
                  (this.state.ListaDatos.length > 0) ?
                    <ListCajaHerramientas
                      appointments={ListaFiltrada}
                    />
                    : <Loader className="main-loader" />
                }

              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default CajaHerramientas;


var accent_map = { 'á': 'a', 'é': 'e', 'è': 'e', 'í': 'i', 'ó': 'o', 'ú': 'u', 'Á': 'a', 'É': 'e', 'E': 'e', 'Í': 'i', 'Ó': 'o', 'Ú': 'u' };
function accent_fold(s) {
  if (!s) { return ''; }
  var ret = '';
  for (var i = 0; i < s.length; i++) {
    ret += accent_map[s.charAt(i)] || s.charAt(i);
  }
  return ret;
};