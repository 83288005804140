import React, { Component } from 'react';

import './Footer.css';

export default class Footer extends Component {
  render() {
    return (
      <footer className="container d-flex text-white py-2">
        <div className="row w-100">
          <p className="col-md-6">
            Desarrollado por
          <a href="https://neva.com.ve" target="_blank" rel="noopener noreferrer"> NEVA Producciones</a>
          </p>
          <p className="col-md-6 text-right">
            <a href="https://juliovalerov.com/adminAsesoria/" target="_blank" rel="noopener noreferrer"> Administrar</a>
          </p>
        </div>

      </footer >
    )
  }
}
