import React, { Component } from 'react';

import './Ejercicios.css';

//own components
import ListEjercicios from './ListEjercicios';

import { config_api } from '../../config';
import Loader from '../Loader';

class Ejercicios extends Component {

  constructor() {
    super();
    this.state = {
      ListaDatos: [],
      lastIndex: 0,
      orderBy: 'ejercicios_nombre',
      orderDir: true,
      queryText: ''
    };
  }

  changeOrder = (order, dir) => {
    this.setState({
      orderBy: order,
      orderDir: dir
    })
  }

  searchApts = (query) => {
    this.setState({
      queryText: query
    })
  }

  toggleOrder = () => {
    this.setState({ orderDir: !this.state.orderDir });
  }

  componentDidMount() {
    fetch(config_api + 'ejercicios.php')
      .then(response => response.json())
      .then(result => {
        
        let items = result.map(item => {
          item.idalimentos = this.state.lastIndex;
          this.setState({ lastIndex: this.state.lastIndex + 1 })
          return item;
        })
        this.setState({
          ListaDatos: items
        })
      }
      );
  }

  render() {

    let order;
    let ListaFiltrada = this.state.ListaDatos;
    if (this.props.orderDir) {
      order = 1;
    } else {
      order = -1;
    }

    ListaFiltrada = ListaFiltrada.sort((a, b) => {
      if (a[this.state.orderBy].toLowerCase() < b[this.state.orderBy].toLowerCase()) {
        return -1 * order;
      } else {
        return 1 * order;
      }
    }).filter(eachItem => {
      return (
        eachItem['ejercicios_nombre']
          .toLowerCase()
          .includes(this.props.queryText.toLowerCase()) ||
          eachItem['ejercicios_musculo']
            .toLowerCase()
            .includes(this.props.queryText.toLowerCase())
      )
    });

    return (
      <main className="page bg-white" id="petratings">
        <div className="container">
          <div className="row">
            <div className="col-md-12 bg-white">
              <div className="container">
                {/* <SearchComponent
                  orderDir={this.state.orderDir}
                  toggleOrder={this.toggleOrder}
                  searchApts={this.searchApts}
                  buscar={'ejercicios'}
                /> */}
                {
                  (this.state.ListaDatos.length > 0) ?
                    <ListEjercicios
                      ejercicios={ListaFiltrada}
                    />
                    : <Loader className="main-loader" />
                }

              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default Ejercicios;
