import React, { Component } from 'react';

import './Header.css';

export default class Header extends Component {

  submitBusqueda = (e) => {
    e.preventDefault();
    return false;
  }

  render() {

    return (
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
        <div className="container">
          <a href="//juliovalerov.com" target="_blank" rel="noopener noreferrer">
            <h4 className="py-2">JulioValeroV</h4>
          </a>
          {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button> */}
        </div>

      </nav>
    )
  }
}
