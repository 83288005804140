import React, { Component } from 'react';
// Router
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";

import Alimentos from '../Alimentos/Alimentos';
import Ejercicios from '../Ejercicios/Ejercicios';
import SearchComponent from '../Comunes/SearchComponent';
import Preguntas from '../Preguntas/Preguntas';
import CajaHerramientas from '../CajaHerramientas/CajaHerramientas';

class Buscadores extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orderDir: true,
      queryText: ''
    }
  }
  searchApts = (query) => {
    this.setState({
      queryText: query
    })
  }

  toggleOrder = () => {
    this.setState({ orderDir: !this.state.orderDir });
  }
  render() {
    return (
      <Router basename="/buscadores">
        <Route render={(routeProps) => <FormularioPrincipal routeProps={routeProps} {...this.state} toggleOrder={this.toggleOrder} searchApts={this.searchApts} />} />
        <Switch>
          <Route path="/alimentos" render={() => <Alimentos queryText={this.state.queryText} orderDir={this.state.orderDir} />} />
          <Route path="/ejercicios" render={() => <Ejercicios queryText={this.state.queryText} orderDir={this.state.orderDir} />} />
          <Route path="/preguntas" render={() => <Preguntas queryText={this.state.queryText} orderDir={this.state.orderDir} />} />
          <Route path="/herramientas" render={() => <CajaHerramientas queryText={this.state.queryText} orderDir={this.state.orderDir} />} />
        </Switch>

      </Router >
    );
  }
}

export default Buscadores;

const FormularioPrincipal = (props) => {
  return (
    <div className="container">
      <div className=" row justify-content-center search-appointments my-4">
        <div className="col-md-6 Formlinks">
          <Link to={'/alimentos'} className={(props.routeProps.location.pathname === '/alimentos' ? 'Formlink active' : 'Formlink')}>Alimentos</Link>
          <Link to={'/ejercicios'} className={(props.routeProps.location.pathname === '/ejercicios' ? 'Formlink active' : 'Formlink')}>Ejercicios</Link>
          <Link to={'/preguntas'} className={(props.routeProps.location.pathname === '/preguntas' ? 'Formlink active' : 'Formlink')}>Dudas</Link>
          <Link to={'/herramientas'} className={(props.routeProps.location.pathname === '/herramientas' ? 'Formlink active' : 'Formlink')}>Herramientas</Link>
        </div>
        <div className="col-md-6">
          {
            !(props.routeProps.location.pathname === '/preguntas') &&
            <SearchComponent
              orderDir={props.orderDir}
              toggleOrder={props.toggleOrder}
              searchApts={props.searchApts}
              {...props.routeProps}
            />
          }

        </div>
      </div>
    </div>
  );
}