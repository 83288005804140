import React, { Component } from 'react';
import Header from './Comunes/Header/Header';
import Footer from './Comunes/Footer/Footer';

// Router
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Buscadores from './Buscadores/Buscadores';
import Herramientas from './Herramientas/Herramientas';

import cookie from 'react-cookies'
import { config_api } from '../config';
import Loader from './Loader';


class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      usuario: null,
      error: '',
      clave: '',
      cargando: false
    }
  }

  componentDidMount() {
    if (cookie.load('userId')) {
      this.setState({ usuario: cookie.load('userId') });
    }
  }

  login = (e) => {
    e.preventDefault()
    if (this.state.clave === "Juliovalerov1") {
      cookie.save('userId', 'julio', { path: '/' })
      this.setState({
        usuario: 'julio'
      })
    }
    else {
      this.setState({ error: "Clave invalida" })
    }
    // this.setState({ cargando: true, error: '' });
    // const options = {
    //   headers: {
    //     'Content-Type': 'application/json'
    //   },
    //   method: 'POST',
    //   body: JSON.stringify(this.state)
    // };
    // fetch(config_api + 'clave.php', options)
    //   .then(response => {
    //     this.setState({ cargando: false });
    //     if (response.status === 200) {
    //       response.json()
    //         .then(result => {
    //           // console.log(result)
    //           if (result.code === "200") {
    //             this.setState({
    //               usuario: result.text
    //             })
    //           } else {
    //             this.setState({ error: result.text });
    //           }

    //         })
    //     } else {
    //       this.setState({ error: response.text });
    //     }
    //   }).catch(e => {
    //     this.setState({ error: e.text });
    //   });

  }

  handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    this.setState({ clave: value });
  }

  render() {
    return (
      <Router>
        <Header />

        {
          (this.state.usuario !== null) ? (
            <Switch>
              <Route path="/" exact component={Herramientas} />
              <Route path="/buscadores" component={Buscadores} />
            </Switch>
          ) : (
            <div>
              <h1 className="text-center text-light mt-5 mb-5">Buscador de Alimentos y Ejercicios</h1>
              <div className="card card-body text-dark mt-5">
                <div className="container text-center">
                  <h3>¡Estas herramientas son solamente para mis asesorados!</h3>
                  <h6>Si todavía no tienes tu asesoría, <a href="https://juliovalerov.com/transformate/" target="_blank">revisa mis planes</a></h6>
                </div>

                <form onSubmit={this.login} id="loginForm">
                  <div className="form-row">
                    <div className="col-md-4 text-center">                  </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="clave_asesorado">Ingrese clave</label>
                      <input
                        type="password"
                        className="form-control"
                        id="clave_asesorado"
                        placeholder="Clave"
                        name="clave_asesorado"
                        required
                        value={this.state.clave}
                        onChange={this.handleChange}
                        autoFocus />

                      {
                        (this.state.cargando) ? <Loader /> : (
                          <input type="submit" className="btn btn-secondary mt-4" id="add-more" value="Ingresar" />
                        )
                      }

                      <label className="btn ml-5 mt-4">{this.state.error}</label>


                    </div>


                  </div>

                </form>
              </div>
            </div>
          )
        }



        <Footer />
      </Router >
    );
  }
}

export default App;