import React, { Component } from 'react';
import YouTube from 'react-youtube';

import InstagramEmbed from 'react-instagram-embed';


import './CajaHerramientas.css';

class ListCajaHerramientas extends Component {

	render() {
		return (
			<div className="lista-videos">
				<h1>Videos</h1>
				<InstagramEmbed
					url='https://www.instagram.com/reel/CWEkQtZj3Cp/?utm_medium=copy_link'
					clientAccessToken='475460277246016|7745faca80bd338ef59a4371bd19ecae'
					maxWidth={320}
					hideCaption={false}
					containerTagName='div'
					protocol=''
					injectScript
				/>
				<div className="row">
					{this.props.appointments.map(item => {

						const video_id = item.idYoutube;
						return (
							<div key={item.idherramientas_videos} className={`col-md-6 col-lg-4 item_herramientas_videos`}>
								<label>{item.nombre_herramientas_videos}</label>

								<YouTube
									videoId={video_id}
									id={video_id}
									className={"embed-responsive-item "}
									containerClassName={"embed-responsive embed-responsive-16by9"}
									opts={{
										playerVars: {
											controls: 2,
											modestbranding: 1,
											showinfo: 0
										},
									}}
								/>
							</div>
						)
					})}
				</div>
			</div>
		);
	}
}

export default ListCajaHerramientas;

// function youtube_parser(url) {
// 	var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
// 	var match = url.match(regExp);
// 	return (match && match[7].length == 11) ? match[7] : false;
// }