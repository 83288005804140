import React, { Component } from 'react';

import './Preguntas.css';

//own components

import { config_api } from '../../config';
import Loader from '../Loader';
import ListaPreguntas from './ListaPreguntas';
import ListaPosts from './ListaPosts';


class Preguntas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      criterio: '',
      buscando: false,
      ListaDatos: [],
      ListaPost: [],
      lastIndex: 0,
    }
  }

  criterioChange = (e) => {
    const value = e.target.value;
    this.setState({ [e.target.name]: value }, () => {
      if (value.length > 2)
        this.realizarBusqueda();
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    if (this.state.criterio.length > 2)
      this.realizarBusqueda()
  }

  realizarBusqueda = () => {

    //hace llamado a APIs para obtener listas
    const valor = this.state.criterio;

    this.setState({ buscando: true, ListaDatos: [],ListaPost: [] });

    fetch(config_api + 'busqueda_pregunta.php?criterio=' + valor)
      .then(response => response.json())
      .then(result => {
        this.setState({
          buscando: false,
          ListaDatos: result
        },this.busquedaAdicional)
      }
      );
  }

  busquedaAdicional = () => {
    const valor = this.state.criterio;

    // this.setState({ ListaPost: [] });

    fetch(config_api + 'busqueda_post.php?criterio=' + valor)
      .then(response => response.json())
      .then(result => {
        this.setState({
          ListaPost: result
        })
      }
      );
  }

  limpiarBusqueda = () =>{
    this.setState({ criterio: '',ListaDatos: [], ListaPost: [] });
  }

  render() {
    return (
      <main className="page bg-white" id="petratings">
        <div className="container">
          <div className="row">
            <div className="col-md-12 bg-white">
              <div className="container">

                <div className="card mt-5 mb-5">
                  <div className="card-body">
                    <h5 className="card-title">Escribe tu duda</h5>
                    <form onSubmit={this.handleSubmit}>
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          name="criterio"
                          className="form-control"
                          placeholder="Escribe tu duda"
                          aria-label="Escribe tu duda"
                          aria-describedby="button-addon2"
                          value={this.state.criterio}
                          onChange={this.criterioChange}
                          autoFocus
                        />
                        <div className="input-group-append">
                          <button onClick={this.limpiarBusqueda} className="btn btn-outline-dark" type="button" id="button-addon3">Limpiar</button>
                        </div>
                        <div className="input-group-append">
                          <button onClick={this.realizarBusqueda} className="btn btn-dark" type="button" id="button-addon2">Buscar</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

                {this.state.buscando && <Loader className="main-loader" />}
                {(this.state.ListaDatos.length > 0) && <ListaPreguntas items={this.state.ListaDatos} />}
                {(this.state.ListaPost.length > 0) && <ListaPosts items={this.state.ListaPost} />}
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default Preguntas;