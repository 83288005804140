import React, { Component } from 'react';


class ListAppointments extends Component {

	render() {
		return (
			<div className="appointment-list item-list mb-3 table-responsive  tableFixHead firstColumnSticky">
				
				<table className="table table-striped">
					<thead>
						<tr>
							<th scope="col" rowSpan="2">Alimento</th>
							{/* <th scope="col" className="text-center" colSpan="5">Macros calculados en base a 100 gramos</th> */}
							<th scope="col">Unidad</th>
							<th scope="col">Carb</th>
							<th scope="col">Fib</th>
							<th scope="col">Prot</th>
							<th scope="col">Grasa</th>
							<th scope="col">Cals</th>

							<th scope="col" rowSpan="2">Se Pesa</th>
						</tr>
						{/* <tr>
							<th scope="col">Carb</th>
							<th scope="col">Fib</th>
							<th scope="col">Prot</th>
							<th scope="col">Grasa</th>
							<th scope="col">Cals</th>
						</tr> */}
					</thead>
					<tbody>
						{this.props.appointments.map(item => (
							<tr key={item.idalimentos}>
								<td>{item.alimentos_descripcion}</td>
								<td>{item.alimentos_unidad}</td>
								<td>{item.alimentos_carb}</td>
								<td>{item.alimentos_fib}</td>
								<td>{item.alimentos_prot}</td>
								<td>{item.alimentos_grasa}</td>
								<td>{item.alimentos_cals}</td>
								<td>{item.alimentos_se_pesa}</td>
							</tr>

						))}
					</tbody>
				</table>
			</div>
		);
	}
}

export default ListAppointments;
