import React, { Component } from 'react';
import {Redirect} from 'react-router-dom'

export default class Herramientas extends Component {
  render() {
    return (
      <div className="herramientas">
        <Redirect to='/buscadores/alimentos' />
      </div>
    )
  }
}
