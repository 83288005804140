import React, { Component } from 'react';


class ListEjercicios extends Component {

	render() {
		return (
			<div className="appointment-list item-list mb-3 table-responsive tableFixHead firstColumnSticky">
				
				<table className="table table-striped">
					<thead>
						<tr>
							<th scope="col" >Ejercicio</th>
							<th scope="col" className="text-center">Músculo</th>
							<th scope="col" >Instagram</th>
							<th scope="col" >YouTube</th>
						</tr>
					</thead>
					<tbody>
						{this.props.ejercicios.map(item => (
							<tr key={item.idejercicios}>
								<td>{item.ejercicios_nombre}</td>
								<td className="text-center">{item.ejercicios_musculo}</td>
								<td><a href={item.ejercicios_instagram} target="_blank" rel="noopener noreferrer">{item.ejercicios_instagram}</a></td>
								<td><a href={item.ejercicios_youtube} target="_blank" rel="noopener noreferrer">{item.ejercicios_youtube}</a></td>
							</tr>

						))}
					</tbody>
				</table>
			</div>
		);
	}
}

export default ListEjercicios;
