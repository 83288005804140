import React, { Component } from 'react';

class ListaPreguntas extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render() {
    const items = this.props.items;
    let itemsRender = items.map((item,index) => {
      return (
        <div className="card" key={'p' + item.idpreguntas}>
          <div className="card-header" id={"heading" + item.idpreguntas}>
            <h2 className="mb-0">

              <button className={(index === 0)? "btn btn-link" : "btn btn-link collapsed"} type="button" data-toggle="collapse" data-target={'#collapseOne'+item.idpreguntas} aria-expanded="true" aria-controls={'collapseOne'+item.idpreguntas}>
                {item.preguntas_pregunta}
              </button>
            </h2>
          </div>

          <div id={'collapseOne'+item.idpreguntas} className={(index === 0)? "collapse show" : "collapse"} aria-labelledby={"heading" + item.idpreguntas} data-parent="#accordionPreguntas">
            <div className="card-body">
              {item.preguntas_respuesta}
            </div>
          </div>
        </div>
      )
    });
    // console.log(items)
    return (
      <div className="accordion mb-5" id="accordionPreguntas" >
        {itemsRender}
      </div>
    );
  }
}

export default ListaPreguntas;