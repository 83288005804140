import React, { Component } from 'react';

class SearchComponent extends Component {
	render() {
		let buscar = 'alimentos';
		if (this.props.location.pathname !== '/') {
			buscar = this.props.location.pathname.substr(1);
		}
		return (

			<div className="input-group">
				<input
					id="SearchApts"
					type="text"
					className="form-control"
					aria-label="Search Appointments"
					onChange={e => this.props.searchApts(e.target.value)}
					placeholder={`Buscar ${buscar}...`}
				/>
				<div className="input-group-append">
					<button
						type="button"
						className="btn btn-primary"
						onClick={e => this.props.toggleOrder()}
					>
						{(this.props.orderDir) ? 'Ascendente' : 'Descendente'} <span className="caret" />
					</button>
				</div>
			</div>
		);
	}
}

export default SearchComponent;
